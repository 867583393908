@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {

   width: 0px;
}

#scroll {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: rgba(100, 100, 100, 0.5) transparent; /* For Firefox */
}

#scroll::-webkit-scrollbar {
  width: 6px; /* Custom width for the scrollbar */
}

#scroll::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.7); /* Custom color for the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
  border: 2px solid rgba(255, 255, 255, 0.2); /* Border for a more distinct appearance */
}

#scroll::-webkit-scrollbar-thumb:hover {
  background-color: rgba(100, 100, 100, 1); /* Darker color when hovered */
  border: 2px solid rgba(255, 255, 255, 0.5); /* More defined border on hover */
}

#scroll::-webkit-scrollbar-track {
  background-color: rgba(240, 240, 240, 0.3); /* Slightly visible background track */
  border-radius: 10px; /* Rounded corners for the track */
}

#scroll::-webkit-scrollbar-track:hover {
  background-color: rgba(240, 240, 240, 0.5); 
}



* {

  padding: 0;
  margin: 0;
}

body, html {

   font-family: "Poppins", sans-serif;
   overflow-x: hidden !important;

}


.delay-\[0ms\] { animation-delay: 100ms; }
.delay-\[200ms\] { animation-delay: 1000ms; }
.delay-\[400ms\] { animation-delay: 2000ms; }
.delay-\[600ms\] { animation-delay: 3000ms; }


.blog li::marker {
  content: default !important; 
}

.blog {
  padding: 40px;
}

.blog div {
display: flex;
flex-direction: column;
gap: 10px

}

.blog h1 {
margin-bottom: 10px;
font-weight: 700;
color: #058373;

}

.blog h2 {
font-size: 22px;
font-weight: 600;
color: black;
}

.blog p {
color: black;
}

.blog ul, .blog ol {
list-style-type: disc;
list-style-position: inside;
padding: 20px;
font-size: 14px;
display: flex;
flex-direction: column;
gap: 5px;
}

.blog li {
font-size: 14px;
}



.check.icon {
  color: #000;
  position: absolute;
  margin-left: 3px;
  margin-top: 4px;
  width: 14px;
  height: 8px;
  border-bottom: solid 2px currentColor;
  border-left: solid 2px currentColor;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}



.no-outline {
  outline: none;
}


select option {
  background-color: rgb(255, 255, 255); 
  
}


@keyframes ping {
  50% {
    opacity: 0.5;
  }
  75%, 100% {
   
    opacity: 1;
  }
}

.animate-ping {
  animation: ping 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}



   @media (min-width: 1600px) {
     .grid-cols-2xl-4 {
       grid-template-columns: repeat(4, minmax(0, 1fr));
     }
     .grid-cols-2xl-5 {
       grid-template-columns: repeat(5, minmax(0, 1fr));
     }
   }
 